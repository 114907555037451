<template>

  <div class="mb-4">
    <Table
        name="Counterparties List"
        :headers="table.header"
        url="/customer/company/counterparties/"
        :getUpdate="table.getUpdate"
        :searchable="table.searchable"
        selectable infinite-scroll
    >

      <template v-slot:name="{row: company}">
      <span>
        <router-link :to="{name: 'counterparty_profile', params: {slug: company.slug}}">
          {{ company.name }}
        </router-link>
      </span>
      </template>

      <template v-slot:assigned_managers="{row: counterparty}">
        <div class="d-flex gap-2 flex-wrap mx-auto" style="max-width: 300px">
          <router-link v-for="manager in counterparty.assigned_managers" :key="`${counterparty.id}_manager_${manager.id}`"
                       :to="{name: 'user_profile', params: {slug: manager.slug}}"
          >
            <span class="badge cursor-pointer"
                  :class="{
              'bg-primary': manager.username.toLowerCase().includes(($route.query.assigned_managers || '/*/').toLowerCase()),
              'badge-soft-primary': !manager.username.toLowerCase().includes(($route.query.assigned_managers || '/*/').toLowerCase())
                  }"
            >{{ manager.username }}</span>
          </router-link>
        </div>
      </template>

<!--      <template v-slot:total_reserved_price="{row: company}">-->
<!--      <span v-if="company.total_reserved_price">-->
<!--          $ {{ parseFloat(company.total_reserved_price).toLocaleString('en-UK') }}-->
<!--      </span>-->
<!--        <span v-else>$0</span>-->
<!--      </template>-->

<!--      <template v-slot:total_act_price="{row: company}">-->
<!--        <div class="d-flex flex-column" v-if="company.total_act_price">-->
<!--          <span>-->
<!--            <i class="bx bx-dollar align-middle"></i>-->
<!--            {{ parseFloat(company.total_act_price || 0).toLocaleString('en-UK') }}-->
<!--          </span>-->
<!--          <span class="text-success">-->
<!--            <i class="bx bx-dollar align-middle"></i>-->
<!--            {{ parseFloat(company.total_completed_act_price || 0).toLocaleString('en-UK') }}-->
<!--          </span>-->
<!--          <span class="text-danger">-->
<!--            <i class="bx bx-dollar align-middle"></i>-->
<!--            {{-->
<!--              (-->
<!--                  parseFloat(company.total_act_price || 0) - parseFloat(company.total_completed_act_price || 0)-->
<!--              )-->
<!--                  .toLocaleString('en-UK')-->
<!--            }}-->
<!--          </span>-->
<!--        </div>-->
<!--        <span v-else>$0</span>-->
<!--      </template>-->

      <template v-slot:total_payment="{row: company}">
      <span v-if="company.total_payment">
          $ {{ parseFloat(company.total_payment).toLocaleString('en-UK') }}
      </span>
        <span v-else>$0</span>
      </template>

      <template v-slot:balance="{row: company}">
      <span v-if="company.balance"
            :class="{
        'text-success': company.balance >= 0,
        'text-danger': company.balance < 0,
            }"
      >
          {{ parseFloat(company.balance).toLocaleString('en-UK') }}
      </span>
        <span v-else class="text-success">$0</span>
      </template>

      <template #top-right>
        <a class="btn btn-success" href="#CreateClientModal" data-bs-toggle="modal"><i
            class="ri-add-line align-bottom me-1"></i>
          Create Customer</a>
      </template>

      <template v-slot:acts="{row: data}">
        <div style="max-width: 200px" class="mx-auto">
          <div class="mb-1">{{ data.completed_count }}/{{ data.act_count }}</div>
          <VTooltip>
            <b-progress :value="(data.completed_count / (data.act_count )) * 100" class="custom-progress"
                        variant="success">
            </b-progress>
            <template v-slot:popper>
              {{ data.completed_count }}/{{ data.act_count }}
            </template>
          </VTooltip>
        </div>
      </template>


      <template v-slot:contracts="{row: data}">
        <div class="d-flex align-items-center justify-content-center gap-4">
          <b-button-group v-if="data.slug && data.contracts">
            <b-dropdown v-if="data.contracts.length > 1" variant="light" dropleft
                        text="View acts">
              <b-dropdown-header>Select a contract</b-dropdown-header>

              <div v-if="data.slug">
                <router-link v-for="contract in data.contracts" :key="`contract_${contract.id}`"
                             :to="{name:'counterparty_profile_contracts_acts', params: {slug: data.slug, contract_slug: contract.slug}}">
                  <b-dropdown-item>
                    {{ contract.name }}
                  </b-dropdown-item>
                </router-link>
              </div>


              <router-link :to="{name: 'counterparty_profile_contracts', params: {slug: data.slug }}">
                <div class="border-top border-soft-dark pt-1 pb-0 px-3">
                  <small class="link-secondary fw-medium ps-1">
                    Show contracts
                    <i class="mdi mdi-arrow-right"></i>
                  </small>
                </div>
              </router-link>
            </b-dropdown>
            <div v-else>
              <router-link v-if="data.slug && data.contracts.length && data.contracts[0].slug"
                           :to="{name:'counterparty_profile_contracts_acts', params: {slug: data.slug, contract_slug: data.contracts[0].slug}}">
                <b-button variant="light">
                  {{ data.contracts[0].name }}
                  <i class="bx bx-link-external ms-1"></i>
                </b-button>
              </router-link>
              <div v-else>
                --
              </div>
            </div>
          </b-button-group>
        </div>
      </template>
    </Table>
  </div>

  <CreateUpdateUser :c="{company_id: current_company.id}"/>
  <CreateClientModal @client-created="table.getUpdate = !table.getUpdate"/>
  <DeleteClientModal @deleted="table.getUpdate = !table.getUpdate" :company="current_company"/>
</template>

<script>
import Table from "@/components/tables/table.vue";


import CreateClientModal from './modals/client_create.vue'
import DeleteClientModal from './modals/client_delete.vue'
import CreateUpdateUser from './modals/CreateUpdateUser.vue'

export default {
  name: "clients_list",
  components: {
    Table,
    CreateUpdateUser,
    CreateClientModal,
    DeleteClientModal
  },
  data() {
    return {
      table: {
        getUpdate: false,
        searchable: true,
        header: [
          {
            label: "Name",
            field: "name",
            align: "center",
          },
          {
            label: "TOTAL PAYMENT",
            field: "total_payment",
            align: "center",
            searchable: false
          },
          {
            label: 'Acts',
            field: 'acts',
            align: 'center',
            searchable: false
          },
          {
            label: "Contracts",
            field: "contracts",
            align: "center",
            searchable: false,
            excel_data: (row) => row.contracts ? row.contracts.length : 0
          },
          {
            label: 'Responsible managers',
            field: 'assigned_managers',
            align: 'center',
          },
        ],
      },

      current_company: {},
    }
  }
}
</script>

<style scoped>

</style>